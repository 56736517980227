// Libraries
import React from 'react';

// Supermove
import {GlobalDashboardModel} from '@supermove/models';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import useUpdateGlobalDashboardStatusMutation from '@shared/modules/GlobalDashboard/hooks/useUpdateGlobalDashboardStatusMutation';

interface RevertToDraftDashboardModalProps {
  globalDashboardForm: GlobalDashboardModel;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const RevertToDraftDashboardModal = ({
  globalDashboardForm,
  isOpen,
  handleClose,
  refetch,
}: RevertToDraftDashboardModalProps) => {
  const {form, handleSubmit, submitting} = useUpdateGlobalDashboardStatusMutation({
    globalDashboardForm: {globalDashboardId: globalDashboardForm.id, status: 'DRAFT'},
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <CautionModal
      isOpen={isOpen}
      title={`Revert to draft?`}
      message={`This report is published to the Reports Library in the Office App. Reverting it to a draft will remove it from the library until it’s published again, but won't affect customers who have already added this report.`}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

export default RevertToDraftDashboardModal;
