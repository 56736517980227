// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import UserForm from '@shared/modules/User/forms/UserForm';

const useUpdateUserRoleMutation = ({userForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      userForm: UserForm.toForm(userForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserRoleMutation.mutation,
    variables: {
      userForm: UserForm.toMutation(form.values.userForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateUserRoleMutation.mutation = gql`
  mutation useUpdateUserRoleMutation($userForm: UserForm!) {
    response: updateUserRole(userForm: $userForm) {
      user {
        id
        fullName
      }
      ${gql.errors}
    }
  }
`;

export default useUpdateUserRoleMutation;
