// Libraries
import React from 'react';

// Supermove
import {useEffect, useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import EditableSortableList from '@shared/modules/App/components/EditableSortableList';
import {useEditableSortableListContext} from '@shared/modules/App/components/EditableSortableList/EditableSortableListContext';
import {DashboardTagFormType} from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import useCreateDashboardTagMutation from '@shared/modules/DashboardTag/hooks/useCreateDashboardTagMutation';
import useUpdateDashboardTagMutation from '@shared/modules/DashboardTag/hooks/useUpdateDashboardTagMutation';
import ManageDashboardTagDeleteModal from 'modules/Dashboards/components/ManageDashboardTagDeleteModal';

const ManageDashboardTagsListItem = ({
  index,
  dashboardTagForm,
  isDisabled,
  handleCancelEdit,
  refetch,
}: {
  index: number;
  dashboardTagForm: DashboardTagFormType;
  isDisabled?: boolean;
  handleCancelEdit: () => void;
  refetch: () => void;
}) => {
  const deleteDashboardTagModal = useModal({name: 'Delete Dashboard Tag Modal'});

  const {setIndexOfEdit} = useEditableSortableListContext();

  useEffect(() => {
    if (dashboardTagForm.dashboardTagId === null) {
      setIndexOfEdit(index);
    }
  }, [dashboardTagForm, index, setIndexOfEdit]);

  const {
    form: formUpdate,
    handleSubmit: handleUpdate,
    submitting: isUpdating,
  } = useUpdateDashboardTagMutation({
    dashboardTagForm,
    onSuccess: refetch,
    onError: (errors) => console.log({errors}),
  });

  const {
    form: formCreate,
    handleSubmit: handleCreate,
    submitting: isCreating,
  } = useCreateDashboardTagMutation({
    dashboardTagForm,
    onSuccess: refetch,
    onError: (errors) => console.log({errors}),
  });

  const handleSubmit = dashboardTagForm.dashboardTagId ? handleUpdate : handleCreate;

  const form = dashboardTagForm.dashboardTagId ? formUpdate : formCreate;

  return (
    <React.Fragment>
      <EditableSortableList.Item
        key={index}
        index={index}
        fieldName={`dashboardTagForm.name`}
        form={form}
        isDisabled={isDisabled}
        isEditable
        itemActions={[
          {
            text: 'Delete Tag',
            color: colors.red.warning,
            onPress: deleteDashboardTagModal.handleOpen,
          },
        ]}
        inputPlaceholder='Enter tag name'
        handleNameSet={handleSubmit}
        handleCancelEdit={handleCancelEdit}
      />
      <ManageDashboardTagDeleteModal
        dashboardTagForm={dashboardTagForm}
        isOpen={deleteDashboardTagModal.isOpen}
        handleClose={deleteDashboardTagModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

export default ManageDashboardTagsListItem;
