// Supermove

// Shared
import {DashboardCategoryModel} from '@supermove/models';

import DashboardCategoryForm, {
  DashboardCategoryFormType,
} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';

const edit = (reportDashboards: DashboardCategoryModel[]) => ({
  dashboardCategoryForms: reportDashboards.map((reportDashboard) => {
    return DashboardCategoryForm.edit(reportDashboard);
  }),
});

const toForm = ({
  dashboardCategoryForms,
}: {
  dashboardCategoryForms: DashboardCategoryFormType[];
}) => ({
  dashboardCategoryForms: dashboardCategoryForms.map((reportDashboardForm) => {
    return DashboardCategoryForm.toForm(reportDashboardForm);
  }),
});

const toMutation = ({
  dashboardCategoryForms,
}: {
  dashboardCategoryForms: DashboardCategoryFormType[];
}) => ({
  dashboardCategoryForms: dashboardCategoryForms.map((reportDashboardForm) => {
    return DashboardCategoryForm.toMutation(reportDashboardForm);
  }),
});

const ReorderDashboardCategoriesForm = {
  edit,
  toForm,
  toMutation,
};

export default ReorderDashboardCategoriesForm;
