// Supermove

// Shared
import {DashboardTagModel} from '@supermove/models';

import DashboardTagForm, {
  DashboardTagFormType,
} from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const edit = (reportDashboards: DashboardTagModel[]) => ({
  dashboardTagForms: reportDashboards.map((reportDashboard) => {
    return DashboardTagForm.edit(reportDashboard);
  }),
});

const toForm = ({dashboardTagForms}: {dashboardTagForms: DashboardTagFormType[]}) => ({
  dashboardTagForms: dashboardTagForms.map((reportDashboardForm) => {
    return DashboardTagForm.toForm(reportDashboardForm);
  }),
});

const toMutation = ({dashboardTagForms}: {dashboardTagForms: DashboardTagFormType[]}) => ({
  dashboardTagForms: dashboardTagForms.map((reportDashboardForm) => {
    return DashboardTagForm.toMutation(reportDashboardForm);
  }),
});

const DashboardTagsForm = {
  edit,
  toForm,
  toMutation,
};

export default DashboardTagsForm;
