// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useMountEffect, useNavigation} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';
import AdminAppPage from 'modules/App/components/AdminAppPage';

import AIPromptTestingPage from './components/AIPromptTestingPage';

const NavigationContainer = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
  height: 100%;
`;

const Container = Styled.View`
    flex: 1;
`;

const Row = Styled.View`
    flex-direction: row;
    flex: 1;
`;

const getAISubPage = ({params}: any) => {
  switch (params.subPage) {
    case 'AI_PROMPT_TESTING':
      return <AIPromptTestingPage />;
    default:
      return null;
  }
};

const AITestingPage = () => {
  const {navigator, params} = useNavigation();
  const navigationItems = [
    {
      key: 'AI_PROMPT_TESTING',
      valueKey: 'subPage',
      label: 'AI Prompt A/B Testing',
      value: 'AI_PROMPT_TESTING',
    },
    {
      key: 'RE_RUN_PROMPTS_FOR_CALL',
      valueKey: 'subPage',
      label: 'Re-run Prompts for Call',
      value: 'RE_RUN_PROMPTS_FOR_CALL',
    },
  ];
  useMountEffect(() => {
    if (!params.subPage) {
      navigator.navigate('AITestingPage', {subPage: 'AI_PROMPT_TESTING'});
    }
  });
  return (
    <AdminAppPage title={'AI Testing'}>
      <Row>
        <NavigationContainer>
          <Space height={16} />
          <TreeNavigation
            width={200}
            handleSetValues={({subPage}: {subPage: string}) =>
              navigator.navigate('AITestingPage', {subPage})
            }
            navigationItems={navigationItems}
            values={params}
          />
        </NavigationContainer>
        <Space height={16} />
        <Container>{getAISubPage({params})}</Container>
      </Row>
    </AdminAppPage>
  );
};

export default AITestingPage;
