// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import ManageDashboardTagsList from 'modules/Dashboards/components/ManageDashboardTagsList';

const ManageDashboardTagsDrawer = ({
  isOpen,
  handleClose,
  refetch: refetchGlobalDashboards,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data, refetch} = useQuery(ManageDashboardTagsDrawer.query, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <DrawerWithClose isOpen={isOpen} handleClose={handleClose} headerText={'Manage Tags'}>
      <Loading loading={!data || loading}>
        {() => (
          <ManageDashboardTagsList
            dashboardTags={data.dashboardTags}
            refetch={() => {
              refetchGlobalDashboards();
              refetch();
            }}
          />
        )}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManageDashboardTagsDrawer.query = gql`
  ${DashboardTagForm.edit.fragment}
  query ManageTagsDrawer {
    ${gql.query}
    dashboardTags {
        id
        ...DashboardTagForm_edit
    }
  }
`;

export default ManageDashboardTagsDrawer;
