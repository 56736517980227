// Supermove
import {createStackNavigator, createSwitchWrapperNavigator} from '@supermove/navigation';

// App
import AITestingPage from 'modules/AI/AITestingPage';
import Root from 'modules/App/Root';
import AuthenticatedWrapper from 'modules/App/components/AuthenticatedWrapper';
import RouterWrapper from 'modules/App/components/RouterWrapper';
import LoginPage from 'modules/Authentication/Login/LoginPage';
import CompaniesListPage from 'modules/Company/CompaniesListPage';
import CompanyDetailsAiSalesCopilotGeneralPage from 'modules/Company/CompanyDetails/CompanyDetailsAiSalesCopilotGeneralPage';
import CompanyDetailsPageMobileNavigation from 'modules/Company/CompanyDetails/CompanyDetailsPageMobileNavigation';
import CompanyDetailsProductsPage from 'modules/Company/CompanyDetails/CompanyDetailsProductsPage';
import CompanyRootPage from 'modules/Company/CompanyRootPage';
import CompanyModulesCalendarsPage from 'modules/Company/Modules/CompanyModulesCalendarsPage';
import CompanyModulesCommunicationPage from 'modules/Company/Modules/CompanyModulesCommunicationPage';
import CompanyModulesEquipmentAndMaterialsPage from 'modules/Company/Modules/CompanyModulesEquipmentAndMaterialsPage';
import CompanyModulesInventoryPage from 'modules/Company/Modules/CompanyModulesInventoryPage';
import CompanyModulesPaymentsPage from 'modules/Company/Modules/CompanyModulesPaymentsPage';
import CompanyModulesStoragePage from 'modules/Company/Modules/CompanyModulesStoragePage';
import CompanyModulesTimesheetsPage from 'modules/Company/Modules/CompanyModulesTimesheetsPage';
import GlobalDashboardDetailsPage from 'modules/Dashboards/DashboardDetails/GlobalDashboardDetailsPage';
import GlobalDashboardsPage from 'modules/Dashboards/GlobalDashboardsPage';
import SuperAdminsListPage from 'modules/SuperAdmin/SuperAdminsListPage';

const Authentication = createStackNavigator(
  {
    LoginPage: {
      path: '',
      screen: LoginPage,
    },
  },
  {
    initialRouteName: 'LoginPage',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const App = createStackNavigator(
  {
    Root: {
      path: '',
      screen: Root,
    },

    // Companies
    CompaniesListPage: {
      path: 'companies',
      screen: CompaniesListPage,
    },

    //
    // COMPANY DETAILS PAGES //
    //
    // Redirects to CompanyDetailsProductsPage
    CompanyRootPage: {
      path: 'companies/:slug',
      screen: CompanyRootPage,
    },
    // Company Details Mobile Navigation Page
    CompanyDetailsPageMobileNavigation: {
      path: 'companies/:slug/settings',
      screen: CompanyDetailsPageMobileNavigation,
    },
    // Company Products Pages
    CompanyDetailsProductsPage: {
      path: 'companies/:slug/company/products',
      screen: CompanyDetailsProductsPage,
    },

    // Company Modules Settings Pages
    CompanyModulesCalendarsPage: {
      path: 'companies/:slug/modules/calendars',
      screen: CompanyModulesCalendarsPage,
    },
    CompanyModulesCommunicationPage: {
      path: 'companies/:slug/modules/communication',
      screen: CompanyModulesCommunicationPage,
    },
    CompanyModulesEquipmentAndMaterialsPage: {
      path: 'companies/:slug/modules/equipment-and-materials',
      screen: CompanyModulesEquipmentAndMaterialsPage,
    },
    CompanyModulesInventoryPage: {
      path: 'companies/:slug/modules/inventory',
      screen: CompanyModulesInventoryPage,
    },
    CompanyModulesPaymentsPage: {
      path: 'companies/:slug/modules/payments',
      screen: CompanyModulesPaymentsPage,
    },
    CompanyModulesStoragePage: {
      path: 'companies/:slug/modules/storage',
      screen: CompanyModulesStoragePage,
    },
    CompanyModulesTimesheetsPage: {
      path: 'companies/:slug/modules/timesheets',
      screen: CompanyModulesTimesheetsPage,
    },

    // AI Sales Copilot General Settings
    CompanyDetailsAiSalesCopilotGeneralPage: {
      path: 'companies/:slug/ai-sales-copilot/general',
      screen: CompanyDetailsAiSalesCopilotGeneralPage,
    },
    // Reports
    GlobalDashboardsPage: {
      path: 'reports',
      screen: GlobalDashboardsPage,
    },

    //
    // GLOBAL REPORT DETAILS PAGES //
    //
    // Global Report Details Page
    GlobalDashboardDetailsPage: {
      path: 'reports/:globalDashboardUuid',
      screen: GlobalDashboardDetailsPage,
    },

    // Super Users
    SuperAdminsListPage: {
      path: 'users/super-admins',
      screen: SuperAdminsListPage,
    },

    // AI
    AITestingPage: {
      path: 'ai',
      screen: AITestingPage,
    },
  },
  {
    initialRouteName: 'Root',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const Authenticated = createSwitchWrapperNavigator(
  {
    App: {
      path: '',
      screen: App,
    },
  },
  {
    initialRouteName: 'App',
    backBehavior: 'history',
    wrapper: AuthenticatedWrapper,
  },
);

// A SwitchWrapperNavigator is a SwitchNavigator with an extra component rendered
// which has access to the `navigation` prop.
const Router = createSwitchWrapperNavigator(
  {
    Authentication: {
      path: 'login',
      screen: Authentication,
    },
    Authenticated: {
      path: '',
      screen: Authenticated,
    },
  },
  {
    initialRouteName: 'Authenticated',
    backBehavior: 'history',
    wrapper: RouterWrapper,
  },
);

export default Router;
