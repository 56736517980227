// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import GlobalDashboardForm from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';
import useCreateGlobalDashboardMutation from '@shared/modules/GlobalDashboard/hooks/useCreateGlobalDashboardMutation';
import EditGlobalDashboardForm from 'modules/Dashboards/components/EditGlobalDashboardForm';

const CreateGlobalDashboardDrawer = ({
  isOpen,
  handleClose,
  handleView,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleView: ({globalDashboardUuid}: {globalDashboardUuid: string}) => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery(CreateGlobalDashboardDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const createGlobalDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard created!',
  });
  const globalDashboardForm = GlobalDashboardForm.new();
  const {form, submitting, handleSubmit} = useCreateGlobalDashboardMutation({
    globalDashboardForm,
    onSuccess: ({globalDashboard}) => {
      createGlobalDashboardSuccessToast.handleToast({
        message: `'${globalDashboard.name}' created as draft!`,
      });
      refetch();
      handleClose();
      handleView({globalDashboardUuid: globalDashboard.uuid});
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Add Report to Library'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={loading}
    >
      <Loading loading={!data || loading}>
        {() => (
          <EditGlobalDashboardForm
            form={form}
            dashboardCategories={data.dashboardCategories}
            dashboardTags={data.dashboardTags}
          />
        )}
      </Loading>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateGlobalDashboardDrawer.query = gql`
  ${DashboardCategoryForm.edit.fragment}
  ${DashboardTagForm.edit.fragment}
  query CreateGlobalDashboardDrawer {
    ${gql.query}
    dashboardCategories {
      id
      ...DashboardCategoryForm_edit
    }
    dashboardTags {
      id
      ...DashboardTagForm_edit
    }
  }
`;

export default CreateGlobalDashboardDrawer;
