// Supremove
import {gql} from '@supermove/graphql';
import {GlobalDashboardModel} from '@supermove/models';
import {URL, withFragment} from '@supermove/utils';

// App
import DashboardCategoryForm, {
  DashboardCategoryFormType,
} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import DashboardTagForm, {
  DashboardTagFormType,
} from '@shared/modules/DashboardTag/forms/DashboardTagForm';

export interface GlobalDashboardFormProps {
  globalDashboardId: string | null;
  name: string;
  description: string;
  helpArticleUrl: string;
  isDefault: boolean;
  exploDashboardEmbedId: string;
  exploVariables: string;
  dashboardCategoryForm: DashboardCategoryFormType | null;
  dashboardTagForms: DashboardTagFormType[];
}

const _new = () => ({
  globalDashboardId: null,
  name: '',
  description: '',
  helpArticleUrl: '',
  isDefault: false,
  exploDashboardEmbedId: '',
  exploVariables: '',
  dashboardCategoryForm: null,
  dashboardTagForms: [],
});

const edit = withFragment(
  (globalDashboard: GlobalDashboardModel) => {
    return {
      globalDashboardId: globalDashboard.id,
      name: globalDashboard.name,
      description: globalDashboard.description,
      helpArticleUrl: globalDashboard.helpArticleUrl,
      isDefault: globalDashboard.isDefault,
      exploDashboardEmbedId: globalDashboard.exploDashboardEmbedId,
      exploVariables: globalDashboard.exploVariables,
      dashboardCategoryForm: DashboardCategoryForm.edit(globalDashboard.dashboardCategory),
      dashboardTagForms:
        globalDashboard.dashboardTags?.map((dashboardTag) =>
          DashboardTagForm.edit(dashboardTag.tag),
        ) || [],
    };
  },
  gql`
    ${DashboardCategoryForm.edit.fragment}
    ${DashboardTagForm.edit.fragment}
    fragment GlobalDashboardForm_edit on GlobalDashboard {
      id
      name
      description
      helpArticleUrl
      isDefault
      exploDashboardEmbedId
      exploVariables
      dashboardCategory {
        ...DashboardCategoryForm_edit
      }
      dashboardTags {
        id
        tag {
          ...DashboardTagForm_edit
        }
      }
    }
  `,
);

const toForm = (globalDashboardForm: GlobalDashboardFormProps) => ({
  globalDashboardId: globalDashboardForm.globalDashboardId,
  name: globalDashboardForm.name,
  description: globalDashboardForm.description,
  helpArticleUrl: globalDashboardForm.helpArticleUrl,
  isDefault: globalDashboardForm.isDefault,
  exploDashboardEmbedId: globalDashboardForm.exploDashboardEmbedId,
  exploVariables: globalDashboardForm.exploVariables,
  dashboardCategoryForm: globalDashboardForm.dashboardCategoryForm
    ? DashboardCategoryForm.toForm(globalDashboardForm.dashboardCategoryForm)
    : null,
  dashboardTagForms: globalDashboardForm.dashboardTagForms.map((dashboardTagForm) =>
    DashboardTagForm.toForm(dashboardTagForm),
  ),
});

const toMutation = (globalDashboardForm: GlobalDashboardFormProps) => {
  const helpArticleUrl = globalDashboardForm.helpArticleUrl.trim();
  return {
    globalDashboardId: globalDashboardForm.globalDashboardId,
    name: globalDashboardForm.name,
    description: globalDashboardForm.description,
    helpArticleUrl: URL.prependHttp(helpArticleUrl),
    isDefault: globalDashboardForm.isDefault,
    exploDashboardEmbedId: globalDashboardForm.exploDashboardEmbedId,
    exploVariables:
      globalDashboardForm.exploVariables && globalDashboardForm.exploVariables.trim()
        ? globalDashboardForm.exploVariables
        : null,
    dashboardCategoryForm: globalDashboardForm.dashboardCategoryForm
      ? DashboardCategoryForm.toMutation(globalDashboardForm.dashboardCategoryForm)
      : null,
    dashboardTagForms: globalDashboardForm.dashboardTagForms.map((dashboardTagForm) =>
      DashboardTagForm.toMutation(dashboardTagForm),
    ),
  };
};

const GlobalDashboardForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default GlobalDashboardForm;
