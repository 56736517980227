// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import SwitchComponent from '@shared/design/components/Switch';

const Container = Styled.View`
  background-color: ${colors.white};
  padding: 8px 24px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const CheckboxWithNameContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ToggleContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Body}
`;

interface ManageCompaniesDrawerListItemProps {
  organizationName: string;
  isPrimary: boolean;
  isEnabled: boolean;
  handleToggle: () => void;
  isSelected: boolean;
  handleSelect: () => void;
}

const ManageGlobalDashboardCompaniesDrawerListItem = ({
  organizationName,
  isPrimary,
  isEnabled,
  handleToggle,
  isSelected,
  handleSelect,
}: ManageCompaniesDrawerListItemProps) => {
  const responsive = useResponsive();
  return (
    <Container>
      <CheckboxWithNameContainer>
        {!isPrimary && <Space width={52} />}
        <Checkbox isChecked={isSelected} handleToggle={handleSelect} />
        <Space width={32} />
        <NameText responsive={responsive}>{organizationName}</NameText>
      </CheckboxWithNameContainer>
      <ToggleContainer>
        <SwitchComponent isOn={isEnabled} onChange={handleToggle} />
        <Space width={24} />
      </ToggleContainer>
    </Container>
  );
};

export default ManageGlobalDashboardCompaniesDrawerListItem;
