// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useNavigation, useQuery, useModal} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import AddSalesAppToCompanyModal from 'modules/Company/CompanyDetails/components/AddSalesAppToCompanyModal';

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SupermoveProductPanelBody = ({description, status}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <BodyText responsive={responsive}>{description}</BodyText>
      <Space height={16} />
      <Badge
        label={_.startCase(_.toLower(status)) || 'Inactive'}
        size={'SMALL'}
        color={
          status === SupermoveProductStatus.ACTIVE ? colors.green.status : colors.gray.secondary
        }
        width={70}
      />
    </React.Fragment>
  );
};

interface SalesAppActionButtonProps {
  product: any;
  organization: any;
  refetch: () => void;
}

const SalesAppActionButton = ({product, organization, refetch}: SalesAppActionButtonProps) => {
  const {navigator, params} = useNavigation();
  const addSalesAppToCompanyModal = useModal({name: 'Add Sales App To Company Modal'});
  const hasActiveCrewAppProduct = product && product.status === SupermoveProductStatus.ACTIVE;
  return (
    <React.Fragment>
      {hasActiveCrewAppProduct ? (
        <TertiaryButton
          isResponsive
          text={'View'}
          onPress={() =>
            navigator.navigate('CompanyDetailsAiSalesCopilotGeneralPage', {slug: params.slug})
          }
        />
      ) : (
        <TertiaryButton
          isResponsive
          text={'Add Product'}
          iconLeft={Icon.Plus}
          onPress={addSalesAppToCompanyModal.handleOpen}
        />
      )}
      <AddSalesAppToCompanyModal
        key={addSalesAppToCompanyModal.key}
        isOpen={addSalesAppToCompanyModal.isOpen}
        handleClose={addSalesAppToCompanyModal.handleClose}
        refetch={refetch}
        organization={organization}
      />
    </React.Fragment>
  );
};

interface SupermoveProductPanelProps {
  title: string;
  description: string;
  status: string;
  ActionButtonComponent?: React.ReactNode;
  actionButtonComponentProps?: any;
}

const SupermoveProductPanel = ({
  title,
  description,
  status,
  ActionButtonComponent,
  actionButtonComponentProps,
}: SupermoveProductPanelProps) => {
  return (
    <ActionPanel
      title={title}
      // @ts-ignore
      BodyComponent={SupermoveProductPanelBody}
      bodyComponentProps={{
        description,
        status,
      }}
      // @ts-ignore
      ActionButtonComponent={ActionButtonComponent}
      actionButtonComponentProps={actionButtonComponentProps}
    />
  );
};

const CompanyDetailsProductsPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CompanyDetailsProductsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'company/products'}
      pageTitle={'Products'}
      pageDescription={'Add and configure Supermove products for this company.'}
    >
      {!loading && (
        <React.Fragment>
          <SupermoveProductPanel
            title={'Office App'}
            description={
              'Main app for back office staff to use including sales, dispatch, operations, and accounting departments.'
            }
            status={data.organization.officeAppProduct?.status}
          />
          <Space height={responsive.desktop ? 16 : 0} />
          <SupermoveProductPanel
            title={'Crew App'}
            description={
              'Tablet app used in the field for documents, billing, payments, and photos.'
            }
            status={data.organization.crewAppProduct?.status}
          />
          <Space height={responsive.desktop ? 16 : 0} />
          <SupermoveProductPanel
            title={'Sales App'}
            description={
              'Sales tool for handling phone calls with AI transcriptions, summaries, and more.'
            }
            status={data.organization.salesAppProduct?.status}
            // @ts-ignore
            ActionButtonComponent={SalesAppActionButton}
            actionButtonComponentProps={{
              product: data.organization.salesAppProduct,
              organization: data.organization,
              refetch,
            }}
          />
        </React.Fragment>
      )}
    </CompanyDetailsPage>
  );
};

CompanyDetailsProductsPage.query = gql`
  ${AddSalesAppToCompanyModal.fragment}

  query CompanyDetailsProductsPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      officeAppProduct: supermoveProduct(kind: "OFFICE") {
        id
        status
      }
      crewAppProduct: supermoveProduct(kind: "CREW") {
        id
        status
      }
      salesAppProduct: supermoveProduct(kind: "SALES") {
        id
        status
      }
      ...AddSalesAppToCompanyModal
    }
  }
`;

export default CompanyDetailsProductsPage;
