// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled} from '@supermove/components';
import {useModal, useResponsive, useSheet} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Drawer from '@shared/design/components/Drawer';
// @ts-ignore
import Modal from '@shared/design/components/Modal';
// @ts-ignore
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ProgressStepper from '@shared/design/components/ProgressModal/ProgressStepper';
import Sheet from '@shared/design/components/Sheet';
import {StepType} from '@shared/modules/App/hooks/useProgress';

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ProgressContainer = Styled.View`
  height: 100%;
  width: 224px;
  padding: 24px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const MobileProgressContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const Container = Styled.View`
  width: 100%;
  max-width: 400px;
`;

const IconButton = ({
  handlePress,
  iconSource,
  color,
}: {
  handlePress: () => void;
  iconSource: IconSource;
  color?: string;
}) => {
  return (
    <TertiaryButton
      onPress={handlePress}
      style={{alignItems: 'center', justifyContent: 'center', width: 24, height: 24}}
      isHitSlop
      slopRadius={8}
    >
      <Icon source={iconSource} size={24} color={color || colors.gray.primary} />
    </TertiaryButton>
  );
};

const Header = ({
  title,
  handleClose,
  handleBack,
}: {
  title: string;
  handleClose: () => void;
  handleBack?: (() => void) | null;
}) => {
  const responsive = useResponsive();
  return (
    <Modal.Header isResponsive style={{backgroundColor: colors.white}}>
      {!responsive.desktop && (
        <React.Fragment>
          {handleBack ? (
            <IconButton iconSource={Icon.AngleLeft} handlePress={handleBack} />
          ) : (
            <Space width={24} />
          )}
          <Space style={{flex: 1}} />
        </React.Fragment>
      )}
      <HeaderText responsive={responsive}>{title}</HeaderText>
      <Space style={{flex: 1}} />
      <IconButton
        iconSource={Icon.Xmark}
        handlePress={handleClose}
        color={responsive.desktop ? colors.gray.secondary : colors.gray.primary}
      />
    </Modal.Header>
  );
};

const ProgressModal = ({
  isOpen,
  handleClose,
  steps,
  completedSteps,
  currentStepKind,
  getStepTitle,
  setCurrentStepKind,
  nextStepToComplete,
  currentStepIndex,
  title,
  BodyElement,
  FooterElement,
  handleBack,
}: {
  isOpen: boolean;
  handleClose: () => void;
  steps: StepType[];
  completedSteps: string[];
  isViewingFirstStep: boolean;
  currentStepKind: string;
  getStepTitle: (kind: string) => string;
  setCurrentStepKind: (value: string | ((prevVar: string) => string)) => void;
  nextStepToComplete: string;
  currentStepIndex: number;
  title: string;
  BodyElement: React.ReactNode;
  FooterElement: React.ReactNode;
  handleBack: (() => void) | null;
}) => {
  const responsive = useResponsive();
  const quitProgressModal = useModal({name: `Quit ${title} Modal`});
  const progressSheet = useSheet({name: `${title} Sheet`});
  const onClose = () => {
    quitProgressModal.handleOpen();
  };

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Modal isOpen={isOpen} width={800} style={{minHeight: 400, maxHeight: 600, height: '100%'}}>
          <Header title={title} handleClose={onClose} />
          <Row style={{flex: 1}}>
            <ProgressContainer>
              <ProgressStepper
                steps={steps}
                currentStep={currentStepKind}
                completedSteps={completedSteps}
                getStepTitle={getStepTitle}
                setCurrentStepKind={setCurrentStepKind}
                nextStepToComplete={nextStepToComplete}
              />
            </ProgressContainer>
            {/* @ts-ignore */}
            <Modal.Body style={{flex: 1}} isScrollable>
              <Container style={{alignSelf: 'center'}}>{BodyElement}</Container>
            </Modal.Body>
          </Row>
          {/* @ts-ignore */}
          <Modal.Footer>{FooterElement}</Modal.Footer>
        </Modal>
      ) : (
        <React.Fragment>
          <Drawer isOpen={isOpen} handleClose={handleClose}>
            <Header title={title} handleClose={onClose} handleBack={handleBack} />
            {/* @ts-ignore */}
            <Drawer.Body bodyScrollStyle={{padding: 0, backgroundColor: colors.gray.background}}>
              <Space height={16} />
              <Row style={{paddingHorizontal: 16}}>
                <TertiaryButton
                  isResponsive
                  onPress={progressSheet.handleOpen}
                  text={`Step ${currentStepIndex + 1} of ${steps.length}`}
                  textColor={colors.gray.secondary}
                  iconRight={Icon.AngleDown}
                />
              </Row>
              <Space height={8} />
              {BodyElement}
            </Drawer.Body>
            <Drawer.FooterContainer style={{padding: 16}}>{FooterElement}</Drawer.FooterContainer>
          </Drawer>
          <Sheet
            isOpen={progressSheet.isOpen}
            handleClose={progressSheet.handleClose}
            headerText={'Progress'}
          >
            <MobileProgressContainer>
              <ProgressStepper
                steps={steps}
                currentStep={currentStepKind}
                completedSteps={completedSteps}
                getStepTitle={getStepTitle}
                setCurrentStepKind={(value) => {
                  setCurrentStepKind(value);
                  progressSheet.handleClose();
                }}
                nextStepToComplete={nextStepToComplete}
              />
            </MobileProgressContainer>
          </Sheet>
        </React.Fragment>
      )}
      <DeleteModal
        key={quitProgressModal.key}
        title={`Quit ${title}`}
        subtitle={'You have unsaved changes. This cannot be undone.'}
        deleteButtonText={'Quit'}
        cancelButtonText={'Cancel'}
        isOpen={quitProgressModal.isOpen}
        handleClose={quitProgressModal.handleClose}
        handleDelete={handleClose}
        isDisabled={false}
      />
    </React.Fragment>
  );
};

export default ProgressModal;
