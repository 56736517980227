// Libraries
import React from 'react';

// Supermove

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import {DashboardTagFormType} from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import useDeleteDashboardTagMutation from '@shared/modules/DashboardTag/hooks/useDeleteDashboardTagMutation';

interface ManageDashboardTagDeleteModalProps {
  dashboardTagForm: DashboardTagFormType;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const ManageDashboardTagDeleteModal = ({
  dashboardTagForm,
  isOpen,
  handleClose,
  refetch,
}: ManageDashboardTagDeleteModalProps) => {
  const {form, handleSubmit, submitting} = useDeleteDashboardTagMutation({
    dashboardTagForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isDisabled={submitting}
      isOpen={isOpen}
      title={`Delete tag?`}
      subtitle={'This will remove this tag from X reports.'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

export default ManageDashboardTagDeleteModal;
