// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover, useToast} from '@supermove/hooks';
import {GlobalDashboardModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Badge from '@shared/design/components/Badge';
import IconButton from '@shared/design/components/IconButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import {DashboardStatusType} from '@shared/modules/GlobalDashboard/enums/DashboardStatus';
import useDuplicateGlobalDashboardMutation from '@shared/modules/GlobalDashboard/hooks/useDuplicateGlobalDashboardMutation';
import ArchiveDashboardModal from 'modules/Dashboards/components/ArchiveDashboardModal';
import PublishDashboardModal from 'modules/Dashboards/components/PublishDashboardModal';
import RestoreAsDraftDashboardModal from 'modules/Dashboards/components/RestoreAsDraftDashboardModal';
import RevertToDraftDashboardModal from 'modules/Dashboards/components/RevertToDraftDashboardModal';
import UpdateGlobalDashboardDrawer from 'modules/Dashboards/components/UpdateGlobalDashboardDrawer';
import getGlobalDashboardStatusChangeActionsMap from 'modules/Dashboards/helpers/getGlobalDashboardStatusChangeActionsMap';

const Container = Styled.View``;

const Card = Styled.ButtonV2`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  background-color: ${colors.white};
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
`;

const DashboardName = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const DashboardDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.gray.border};
`;

interface ResponsiveType {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
}

interface GlobalDashboardCardProps {
  globalDashboard: GlobalDashboardModel;
  responsive: ResponsiveType;
  style: object;
  refetch: () => void;
  handleView: ({globalDashboardUuid}: {globalDashboardUuid: string}) => void;
}

type MemoGlobalDashboardCardType = React.MemoExoticComponent<
  ({
    globalDashboard,
    responsive,
    style,
    refetch,
    handleView,
  }: GlobalDashboardCardProps) => JSX.Element
> & {fragment?: any};

// Without React.memo, the FlatList re-renders all items and performance is impacted
const GlobalDashboardCard: MemoGlobalDashboardCardType = React.memo(
  ({globalDashboard, responsive, style, refetch, handleView}: GlobalDashboardCardProps) => {
    const actionsPopover = usePopover();
    const publishGlobalDashboardModal = useModal({name: 'Publish Global Dashboard Modal'});
    const archiveGlobalDashboardModal = useModal({name: 'Archive Global Dashboard Modal'});
    const revertToDraftGlobalDashboardModal = useModal({
      name: 'Revert To Draft Global Dashboard Modal',
    });
    const restoreAsDraftGlobalDashboardModal = useModal({
      name: 'Restore As Draft Global Dashboard Modal',
    });
    const updateGlobalDashboardDrawer = useDrawer({name: 'Update Global Dashboard Drawer'});

    const duplicateGlobalDashboardSuccessToast = useToast({
      ToastComponent: SuccessToast,
      message: 'Dashboard created!',
      actionText: 'View',
      handleAction: ({uuid}: GlobalDashboardModel) => {
        handleView({globalDashboardUuid: uuid});
      },
    });
    const {
      form,
      submitting,
      handleSubmit: handleDuplicate,
    } = useDuplicateGlobalDashboardMutation({
      duplicateGlobalDashboardForm: {globalDashboardId: globalDashboard.id},
      onSuccess: ({globalDashboard}: {globalDashboard: GlobalDashboardModel}) => {
        duplicateGlobalDashboardSuccessToast.handleToast({
          message: `'${globalDashboard.name}' created!`,
          actionPayload: globalDashboard,
        });
        refetch();
      },
      onError: (errors: any) => console.log({errors}),
    });

    const convertStatusToActionsList = (status: DashboardStatusType) => {
      const statusChangeActionsMap = getGlobalDashboardStatusChangeActionsMap({
        handleDuplicate,
        handlePublish: publishGlobalDashboardModal.handleOpen,
        handleRestoreAsDraft: restoreAsDraftGlobalDashboardModal.handleOpen,
        handleRevertToDraft: revertToDraftGlobalDashboardModal.handleOpen,
        handleArchive: archiveGlobalDashboardModal.handleOpen,
      });
      if (status === 'ARCHIVED') {
        return statusChangeActionsMap.ARCHIVED;
      }

      const actions = [
        {text: 'Edit report', onPress: updateGlobalDashboardDrawer.handleOpen},
        ...statusChangeActionsMap[status],
      ];

      return actions;
    };

    const actions = convertStatusToActionsList(globalDashboard.status);
    return (
      <React.Fragment>
        <Card style={style} onPress={() => handleView({globalDashboardUuid: globalDashboard.uuid})}>
          <Row style={{alignItems: 'flex-start'}}>
            {globalDashboard.isDefault && (
              <Row>
                <TextTooltip
                  text={'This report is added to all companies by default.'}
                  placement={'top'}
                >
                  <Container>
                    <Badge label={'Default'} />
                  </Container>
                </TextTooltip>
                <Space width={8} />
              </Row>
            )}
            <DashboardName responsive={responsive}>{globalDashboard.name}</DashboardName>
            <Space style={{flex: 1, minWidth: 8}} />
            <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
              <IconButton
                onPress={actionsPopover.handleToggle}
                isSecondary
                source={Icon.EllipsisV}
              />
            </ActionMenuPopover>
          </Row>
          <Space height={8} />
          <Row>
            <DashboardDescription responsive={responsive}>
              {globalDashboard.description}
            </DashboardDescription>
          </Row>
          <Space style={{flex: 1, minHeight: 8}} />
          <Line />
          <Space height={8} />
          <Row>
            <Container style={{flex: 1}}>
              <MicroLabelText responsive={responsive}>Category</MicroLabelText>
              <MicroText responsive={responsive}>
                {globalDashboard.dashboardCategory.name}
              </MicroText>
            </Container>
            <Space width={8} />
            <Container style={{flex: 1}}>
              <MicroLabelText responsive={responsive}>Added By</MicroLabelText>
              {/* Normally, we'd use pluralize; however, it doesn't provide support for comma separated strings */}
              <MicroText responsive={responsive}>
                {globalDashboard.dashboardCount === 1
                  ? '1 company'
                  : `${globalDashboard.dashboardCount.toLocaleString()} companies`}
              </MicroText>
            </Container>
          </Row>
        </Card>
        <PublishDashboardModal
          isOpen={publishGlobalDashboardModal.isOpen}
          handleClose={publishGlobalDashboardModal.handleClose}
          refetch={refetch}
          globalDashboardForm={globalDashboard}
        />
        <ArchiveDashboardModal
          isOpen={archiveGlobalDashboardModal.isOpen}
          handleClose={archiveGlobalDashboardModal.handleClose}
          refetch={refetch}
          globalDashboardForm={globalDashboard}
        />
        <RevertToDraftDashboardModal
          isOpen={revertToDraftGlobalDashboardModal.isOpen}
          handleClose={revertToDraftGlobalDashboardModal.handleClose}
          refetch={refetch}
          globalDashboardForm={globalDashboard}
        />
        <RestoreAsDraftDashboardModal
          isOpen={restoreAsDraftGlobalDashboardModal.isOpen}
          handleClose={restoreAsDraftGlobalDashboardModal.handleClose}
          refetch={refetch}
          globalDashboardForm={globalDashboard}
        />
        <UpdateGlobalDashboardDrawer
          key={updateGlobalDashboardDrawer.key}
          isOpen={updateGlobalDashboardDrawer.isOpen}
          globalDashboardUuid={globalDashboard.uuid}
          handleClose={updateGlobalDashboardDrawer.handleClose}
          refetch={refetch}
        />
      </React.Fragment>
    );
  },
  (prevProps, nextProps) =>
    prevProps.globalDashboard.id === nextProps.globalDashboard.id &&
    prevProps.globalDashboard.name === nextProps.globalDashboard.name &&
    prevProps.globalDashboard.description === nextProps.globalDashboard.description &&
    prevProps.globalDashboard.dashboardCount === nextProps.globalDashboard.dashboardCount &&
    prevProps.globalDashboard.isDefault === nextProps.globalDashboard.isDefault &&
    prevProps.globalDashboard.dashboardCategory.id ===
      nextProps.globalDashboard.dashboardCategory.id &&
    prevProps.globalDashboard.dashboardCategory.name ===
      nextProps.globalDashboard.dashboardCategory.name &&
    _.isEqual(prevProps.responsive, nextProps.responsive),
);

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalDashboardCard.fragment = gql`
  fragment GlobalDashboardCard on GlobalDashboard {
    id
    uuid
    name
    description
    isDefault
    status
    dashboardCount
    dashboardCategory {
      id
      name
    }
  }
`;

export default GlobalDashboardCard;
