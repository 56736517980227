// Libraries
import React from 'react';

// Supermove
import {ZendeskWebWidget} from '@supermove/analytics';
import {SupermoveApp, MaintenancePage} from '@supermove/app';
import {ToastContainer, UpdateChecker} from '@supermove/components';
import {Environment} from '@supermove/sdk';

// App
import {AppInfo} from 'config';
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppBanner from 'modules/App/components/UpdateAppBanner';
import AppContextProvider from 'modules/App/context/AppContextProvider';

const App = ({router: Router}) => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');
  const zendeskWebWidgetKey = Environment.get('ZENDESK_WEB_WIDGET_KEY');
  const isZendeskChatVisible = false;

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <ErrorModal
      trigger={({handleOpen}) => (
        <React.Fragment>
          <SupermoveApp
            name={'Admin'}
            version={AppInfo.getVersion()}
            buildNumber={AppInfo.getBuildNumber()}
            onError={() => {
              handleOpen();
              if (window.zE) {
                // Opens the Zendesk chat widget:
                // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open
                window.zE('messenger', 'open');
              }
            }}
            showOfflineOverlay={false}
          >
            {() => (
              <React.Fragment>
                <UpdateChecker
                  name={'Admin'}
                  buildNumber={AppInfo.getBuildNumber()}
                  pollInterval={1000 * 30}
                >
                  {({isVisible}) =>
                    isVisible && !Environment.isLocal ? <UpdateAppBanner /> : null
                  }
                </UpdateChecker>
                <AppContextProvider>
                  <Router />
                </AppContextProvider>
                <ToastContainer />
              </React.Fragment>
            )}
          </SupermoveApp>
          <ZendeskWebWidget
            widgetKey={zendeskWebWidgetKey}
            isVisible={isZendeskChatVisible}
            options={{
              webWidget: {
                offset: {
                  horizontal: '75px',
                  vertical: '0px',
                },
              },
            }}
          />
        </React.Fragment>
      )}
    />
  );
};

export default App;
