import React from 'react';

// Libraries
import {PageContext} from '@supermove/analytics';
import {useActiveRoute, useTrackPageview} from '@supermove/hooks';

const RouterWrapper = ({navigation, children}) => {
  const {route} = useActiveRoute({navigation});
  const {routeName} = route;
  useTrackPageview({routeName});
  return <PageContext.Provider value={{pageName: routeName}}>{children}</PageContext.Provider>;
};

export default RouterWrapper;
