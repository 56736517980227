// Libraries
import React from 'react';

// Supermove
import {FlatList, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useQuery, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import IconButton from '@shared/design/components/IconButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SearchBar from '@shared/design/components/SearchBar';
import OrganizationItem from 'modules/Dashboards/DashboardDetails/components/OrganizationItem';

const Container = Styled.View`
`;

const ListContainer = Styled.View`
`;

const Line = Styled.View`
  width: 100%;
  height: 1px;
  background-color: ${colors.gray.border};
`;

const EmptyStateContainer = Styled.View`
  height: 200px;
  justify-content: center;
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Column = Styled.View`
`;

const ModalHeading = Styled.Text`
  ${Typography.Responsive.Subheading}
`;
const ModalDescription = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;
const ModalDescriptionSlug = Styled.Text`
`;

interface OrganizationData {
  id: string;
  name: string;
  slug: string;
  exploUserGroupToken: string;
}

const NoOrganization = () => {
  return (
    <EmptyStateContainer>
      <EmptyState title={'No results.'} message={'Clear your search and try again.'} />
    </EmptyStateContainer>
  );
};

interface SelectModalHeaderProps {
  organizationSlug?: string;
  onClose: () => void;
}

const SelectModalHeader = ({organizationSlug, onClose}: SelectModalHeaderProps) => {
  const responsive = useResponsive();
  return (
    <Row style={{flex: 1}}>
      <Column style={{paddingHorizontal: '8px', paddingVertical: '4px'}}>
        <Icon color={colors.blue.interactive} size={20} source={Icon.Eye} />
      </Column>
      <Column style={{flex: 1}}>
        <ModalHeading responsive={responsive}>Preview As</ModalHeading>
        <ModalDescription responsive={responsive}>
          {'Current: '}
          <ModalDescriptionSlug responsive={responsive}>{organizationSlug}</ModalDescriptionSlug>
        </ModalDescription>
      </Column>
      <Column style={{paddingHorizontal: '8px', paddingVertical: '4px'}}>
        <IconButton onPress={onClose} source={Icon.Xmark} isSecondary size={20} />
      </Column>
    </Row>
  );
};

interface SelectOrganizationModalProps {
  isOpen: boolean;
  selectedOrganizationSlug?: string;
  handleOrganizationSelect: (organizationSlug: string) => void;
  handleClose: () => void;
}

const SelectOrganizationModal = ({
  isOpen,
  selectedOrganizationSlug,
  handleOrganizationSelect,
  handleClose,
}: SelectOrganizationModalProps) => {
  const responsive = useResponsive();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredOrganizations, setFilteredOrganizations] = useState<OrganizationData[]>([]);

  const {loading, data} = useQuery(SelectOrganizationModal.query, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.activeOrganizations) {
      const filtered = (data.activeOrganizations as OrganizationData[])
        .filter((organization: OrganizationData) => {
          return (
            organization.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            organization.slug.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => a.slug.localeCompare(b.slug));
      setFilteredOrganizations(filtered);
    }
  }, [searchQuery, data]);

  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  return (
    <SmallModal
      isOpen={isOpen}
      isMobileSheet
      isFixedHeightSheet
      sheetHeaderContainerStyle={{padding: 0}}
      sheetBodyStyle={{padding: 0}}
      handleClose={handleClose}
      handlePressOutside={handleClose}
      headerComponent={
        <Container
          style={{
            padding: 16,
            backgroundColor: colors.white,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
          }}
        >
          <SelectModalHeader organizationSlug={selectedOrganizationSlug} onClose={handleClose} />
        </Container>
      }
      handleAction={undefined}
      style={{padding: 0}}
    >
      <Container style={{padding: 16, backgroundColor: colors.gray50}}>
        <SearchBar
          placeholder='Search'
          defaultValue={searchQuery}
          onChangeText={handleSearch}
          style={{width: '100%'}}
        />
        <Space height={8} />
        <ListContainer style={responsive.desktop ? {height: '290px'} : {flex: 1}}>
          {loading ? (
            <Loading />
          ) : (
            <FlatList
              data={filteredOrganizations}
              keyExtractor={(organization: OrganizationData) => organization.id}
              renderItem={({
                item: organization,
                index,
              }: {
                item: OrganizationData;
                index: number;
              }) => (
                <OrganizationItem
                  organization={organization}
                  isSelected={selectedOrganizationSlug == organization.slug}
                  handlePress={() => handleOrganizationSelect(organization.slug)}
                />
              )}
              ItemSeparatorComponent={Line}
              ListEmptyComponent={NoOrganization}
              initialNumToRender={5}
            />
          )}
        </ListContainer>
      </Container>
    </SmallModal>
  );
};

SelectOrganizationModal.query = gql`
  ${OrganizationItem.fragment}

  query SelectOrganizationModal {
    ${gql.query}
    activeOrganizations {
      id
      ...OrganizationItem
    }
  }
`;

export default SelectOrganizationModal;
