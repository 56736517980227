// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import ManageDashboardCategoriesList from 'modules/Dashboards/components/ManageDashboardCategoriesList';

const ManageDashboardCategoriesDrawer = ({
  isOpen,
  handleClose,
  refetch: refetchGlobalDashboards,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data, refetch} = useQuery(ManageDashboardCategoriesDrawer.query, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  return (
    <DrawerWithClose isOpen={isOpen} handleClose={handleClose} headerText={'Manage Categories'}>
      <Loading loading={!data || loading}>
        {() => (
          <ManageDashboardCategoriesList
            dashboardCategories={data.dashboardCategories}
            refetch={() => {
              refetchGlobalDashboards();
              refetch();
            }}
          />
        )}
      </Loading>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManageDashboardCategoriesDrawer.query = gql`
  ${DashboardCategoryForm.edit.fragment}
  query ManageCategoriesDrawer {
    ${gql.query}
    dashboardCategories {
        id
        ...DashboardCategoryForm_edit
    }
  }
`;

export default ManageDashboardCategoriesDrawer;
