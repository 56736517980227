// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import {DashboardCategoryFormType} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import ReorderDashboardCategoriesForm from '@shared/modules/DashboardCategory/forms/ReorderDashboardCategoriesForm';

const useReorderDashboardCategoriesMutation = ({
  reorderDashboardCategoriesForm,
  onSuccess,
  onError,
}: {
  reorderDashboardCategoriesForm: {dashboardCategoryForms: DashboardCategoryFormType[]};
  onSuccess: () => void;
  onError: () => void;
}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm.toForm(
        reorderDashboardCategoriesForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useReorderDashboardCategoriesMutation.mutation,
    variables: {
      reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm.toMutation(
        form.values.reorderDashboardCategoriesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useReorderDashboardCategoriesMutation.mutation = gql`
  mutation useReorderDashboardCategoriesMutation($reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm!) {
    response: reorderDashboardCategories(reorderDashboardCategoriesForm: $reorderDashboardCategoriesForm) {
      ${gql.errors}
      dashboardCategories {
        id
      }
    }
  }
`;

export default useReorderDashboardCategoriesMutation;
