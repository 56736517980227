// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useForm} from '@supermove/hooks';
import {DashboardTagModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import EditableSortableList from '@shared/modules/App/components/EditableSortableList';
import DashboardTagForm, {
  DashboardTagFormType,
} from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import DashboardTagsForm from '@shared/modules/DashboardTag/forms/DashboardTagsForm';
import ManageDashboardTagsEmptyState from 'modules/Dashboards/components/ManageDashboardTagsEmptyState';
import ManageDashboardTagsListItem from 'modules/Dashboards/components/ManageDashboardTagsListItem';

const Container = Styled.View`
`;

interface ManageDashboardTagsListProps {
  dashboardTags: DashboardTagModel[];
  refetch: () => void;
}

const ManageDashboardTagsList = ({dashboardTags, refetch}: ManageDashboardTagsListProps) => {
  const dashboardTagsForm = DashboardTagsForm.edit(dashboardTags);

  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      dashboardTagsForm: DashboardTagsForm.toForm(dashboardTagsForm),
    },
  });

  const dashboardTagForms = form.values.dashboardTagsForm
    .dashboardTagForms as DashboardTagFormType[];

  const handleCreate = () => {
    form.setFieldValue('dashboardTagsForm.dashboardTagForms', [
      ...dashboardTagForms,
      DashboardTagForm.new({name: ''}),
    ]);
  };

  const handleCancelEdit = (dashboardTagForm: DashboardTagFormType, index: number) => {
    if (!dashboardTagForm.dashboardTagId) {
      const cleanedItems = List.remove(dashboardTagForms, index);
      form.setFieldValue('reorderDashboardTagsForm.dashboardTagForms', cleanedItems);
    }
  };

  return (
    <Container>
      <Callout text='Descriptive tags to help customers easily find reports based on keywords and topics.' />
      <Space height={16} />
      {dashboardTagForms.length > 0 ? (
        <React.Fragment>
          <EditableSortableList
            isDisabled
            dragAndDropOptions={{
              isReordering: false,
              handleReorderStart: () => {}, // It is non-reorderable
              handleReorderEnd: () => {},
            }}
            handleReorder={() => {}}
          >
            {dashboardTagForms.map((dashboardTagForm, index) => (
              <ManageDashboardTagsListItem
                key={dashboardTagForm.dashboardTagId}
                index={index}
                dashboardTagForm={dashboardTagForm}
                refetch={refetch}
                handleCancelEdit={() => handleCancelEdit(dashboardTagForm, index)}
              />
            ))}
          </EditableSortableList>
          <Space height={8} />
          <TertiaryButton
            text='Add Tag'
            onPress={handleCreate}
            iconLeft={Icon.Plus}
            style={{
              paddingVertical: 8,
              paddingHorizontal: 24,
            }}
          />
        </React.Fragment>
      ) : (
        <ManageDashboardTagsEmptyState handleCreate={handleCreate} />
      )}
    </Container>
  );
};

export default ManageDashboardTagsList;
