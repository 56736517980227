// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';

// App
import EmptyState from '@shared/design/components/EmptyState';

const Container = Styled.View`
`;

interface ManageGlobalDashboardCompaniesDrawerListEmptyStateProps {}

const ManageGlobalDashboardCompaniesDrawerListEmptyState =
  ({}: ManageGlobalDashboardCompaniesDrawerListEmptyStateProps) => {
    return (
      <Container>
        <Space height={32} />
        <EmptyState title={'No active companies to manage.'} />
        <Space height={32} />
      </Container>
    );
  };

export default ManageGlobalDashboardCompaniesDrawerListEmptyState;
