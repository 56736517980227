// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {DashboardCategoryModel} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import {DashboardCategoryFormType} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import useDeleteDashboardCategoryMutation from '@shared/modules/DashboardCategory/hooks/useDeleteDashboardCategoryMutation';

const getSubtitle = (globalDashboardsAndDashboardsCount: number, name: string) => {
  if (globalDashboardsAndDashboardsCount > 0) {
    return `There are ${globalDashboardsAndDashboardsCount} reports in the '${name}' category. Select another category or rename this one to recategorize them.`;
  }

  return `Are you sure you want to delete the '${name}' category?`;
};

const getDashboardCategoryOptions = (
  dashboardCategories: DashboardCategoryModel[],
  dashboardCategoryId: number | null,
) => {
  return dashboardCategories
    .filter((category) => category.id !== dashboardCategoryId)
    .map((category) => ({
      label: category.name,
      value: category.id,
    }));
};

interface ManageDashboardCategoryDeleteModalProps {
  dashboardCategoryForm: DashboardCategoryFormType;
  dashboardCategories: DashboardCategoryModel[];
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const ManageDashboardCategoryDeleteModal = ({
  dashboardCategoryForm,
  dashboardCategories,
  isOpen,
  handleClose,
  refetch,
}: ManageDashboardCategoryDeleteModalProps) => {
  const {data, loading} = useQuery(ManageDashboardCategoryDeleteModal.query, {
    variables: {
      dashboardCategoryId: dashboardCategoryForm.dashboardCategoryId,
    },
  });

  const {form, handleSubmit, submitting} = useDeleteDashboardCategoryMutation({
    dashboardCategoryForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const dashboardCategoryOptions = getDashboardCategoryOptions(
    dashboardCategories,
    dashboardCategoryForm.dashboardCategoryId,
  );

  const field = 'dashboardCategoryForm';
  const replaceWithDashboardCategoryIdField = `${field}.replaceWithDashboardCategoryId`;
  const replaceWithDashboardCategoryId = _.get(form.values, replaceWithDashboardCategoryIdField);

  const globalDashboardsAndDashboardsCount =
    data?.dashboardCategory.globalDashboardsAndDashboardsCount;
  const subtitle = getSubtitle(globalDashboardsAndDashboardsCount, dashboardCategoryForm.name);

  return (
    <DeleteModal
      isDisabled={submitting}
      isOpen={isOpen}
      title={`Delete category?`}
      subtitle={subtitle}
      isSubmitting={loading || submitting}
      handleClose={handleClose}
      handleDelete={handleSubmit}
    >
      {globalDashboardsAndDashboardsCount > 0 && (
        <React.Fragment>
          <Space height={16} />
          <FieldInput
            {...form}
            index={1}
            label={'Select category'}
            isResponsive
            isRequired
            component={DropdownInput}
            style={{width: '100%'}}
            input={{
              value: replaceWithDashboardCategoryId,
              options: dashboardCategoryOptions,
              placeholder: `Type to search`,
              setFieldValue: (name: string, value: any) => {
                form.setFieldValue(replaceWithDashboardCategoryIdField, value);
              },
              style: {flex: 1},
              noOptionsMessage: () => 'No categories found.',
              isPortaled: true,
            }}
          />
        </React.Fragment>
      )}
    </DeleteModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ManageDashboardCategoryDeleteModal.query = gql`
  query ManageDashboardCategoryDeleteModal($dashboardCategoryId: Int!) {
    ${gql.query}
    dashboardCategory(dashboardCategoryId: $dashboardCategoryId) {
      id
      globalDashboardsAndDashboardsCount
    }
  }
`;

export default ManageDashboardCategoryDeleteModal;
