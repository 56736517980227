// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useDragAndDrop} from '@supermove/hooks';
import {DashboardCategoryModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import EditableSortableList from '@shared/modules/App/components/EditableSortableList';
import DashboardCategoryForm, {
  DashboardCategoryFormType,
} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import ReorderDashboardCategoriesForm from '@shared/modules/DashboardCategory/forms/ReorderDashboardCategoriesForm';
import useReorderDashboardCategoriesMutation from '@shared/modules/DashboardCategory/hooks/useReorderDashboardCategoriesMutation';
import ManageDashboardCategoriesEmptyState from 'modules/Dashboards/components/ManageDashboardCategoriesEmptyState';
import ManageDashboardCategoriesListItem from 'modules/Dashboards/components/ManageDashboardCategoriesListItem';

const Container = Styled.View`
`;

interface ManageDashboardCategoriesListProps {
  dashboardCategories: DashboardCategoryModel[];
  refetch: () => void;
}

const ManageDashboardCategoriesList = ({
  dashboardCategories,
  refetch,
}: ManageDashboardCategoriesListProps) => {
  const reorderDashboardCategoriesForm = ReorderDashboardCategoriesForm.edit(dashboardCategories);

  const dragAndDropOptions = useDragAndDrop();
  const {form, handleSubmit, submitting} = useReorderDashboardCategoriesMutation({
    reorderDashboardCategoriesForm,
    onSuccess: () => {
      refetch();
      dragAndDropOptions.handleReorderEnd();
    },
    onError: () => {
      dragAndDropOptions.handleReorderEnd();
    },
  });

  const dashboardCategoryForms = form.values.reorderDashboardCategoriesForm
    .dashboardCategoryForms as DashboardCategoryFormType[];

  const handleCreate = () => {
    form.setFieldValue('reorderDashboardCategoriesForm.dashboardCategoryForms', [
      ...dashboardCategoryForms,
      DashboardCategoryForm.new({name: ''}),
    ]);
  };

  const handleCancelEdit = (dashboardCategoryForm: DashboardCategoryFormType, index: number) => {
    if (!dashboardCategoryForm.dashboardCategoryId) {
      const cleanedItems = List.remove(dashboardCategoryForms, index);
      form.setFieldValue('reorderDashboardCategoriesForm.dashboardCategoryForms', cleanedItems);
    }
  };

  const handleReorder = ({fromIndex, toIndex}: {fromIndex: number; toIndex: number}) => {
    dragAndDropOptions.handleReorderStart();
    const reorderedItems = EditableSortableList.getReorderedItems({
      items: dashboardCategoryForms,
      fromIndex,
      toIndex,
    });
    form.setFieldValue('reorderDashboardCategoriesForm.dashboardCategoryForms', reorderedItems);
    setTimeout(handleSubmit, 0);
  };

  return (
    <Container>
      <Callout text='Categories provide high-level organization for reports.' />
      <Space height={16} />
      {dashboardCategories.length > 0 ? (
        <React.Fragment>
          <EditableSortableList
            dragAndDropOptions={dragAndDropOptions}
            handleReorder={handleReorder}
          >
            {dashboardCategoryForms.map((dashboardCategoryForm, index) => (
              <ManageDashboardCategoriesListItem
                key={dashboardCategoryForm.dashboardCategoryId}
                index={index}
                dashboardCategoryForm={dashboardCategoryForm}
                isDisabled={submitting}
                dashboardCategories={dashboardCategories}
                refetch={refetch}
                handleCancelEdit={() => handleCancelEdit(dashboardCategoryForm, index)}
              />
            ))}
          </EditableSortableList>
          <Space height={8} />
          <TertiaryButton
            text='Add Category'
            onPress={handleCreate}
            iconLeft={Icon.Plus}
            style={{
              paddingVertical: 8,
              paddingHorizontal: 24,
            }}
          />
        </React.Fragment>
      ) : (
        <ManageDashboardCategoriesEmptyState handleCreate={handleCreate} />
      )}
    </Container>
  );
};

export default ManageDashboardCategoriesList;
