// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import DashboardTagForm, {
  DashboardTagFormType,
} from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const useDeleteDashboardTagMutation = ({
  dashboardTagForm,
  onSuccess,
  onError,
}: {
  dashboardTagForm: DashboardTagFormType;
  onSuccess: () => void;
  onError: (errors: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardTagForm: DashboardTagForm.toForm(dashboardTagForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteDashboardTagMutation.mutation,
    variables: {
      dashboardTagForm: DashboardTagForm.toMutation(form.values.dashboardTagForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteDashboardTagMutation.mutation = gql`
  mutation useDeleteDashboardTagMutation($dashboardTagForm: DashboardTagForm!) {
    response: deleteDashboardTag(dashboardTagForm: $dashboardTagForm) {
      ${gql.errors}
      dashboardTag {
        id
      }
    }
  }
`;

export default useDeleteDashboardTagMutation;
