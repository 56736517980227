// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useQuery, useToast} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import Callout from '@shared/design/components/Callout';
import Drawer from '@shared/design/components/Drawer';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserForm from '@shared/modules/User/forms/UserForm';
import useUpdateUserRoleMutation from '@shared/modules/User/hooks/useUpdateUserRoleMutation';

const FieldsContainer = Styled.View`
  padding-vertical: 16px;
`;

const getSuperAdminOptions = ({superAdminOptions}: {superAdminOptions: any}) => {
  return superAdminOptions.map((superAdminOption: any) => ({
    label: superAdminOption.fullName,
    value: superAdminOption.id,
  }));
};

interface AssignSuperAdminDrawerProps {
  isOpen: boolean;
  handleClose: any;
  refetch: any;
}

const AssignSuperAdminDrawer = ({isOpen, handleClose, refetch}: AssignSuperAdminDrawerProps) => {
  const responsive = useResponsive();
  const successToast = useToast({ToastComponent: SuccessToast});
  const userForm = UserForm.new({});

  const {data, loading, error} = useQuery(AssignSuperAdminDrawer.query, {
    fetchPolicy: 'cache-and-network',
  });

  const {form, handleSubmit, submitting} = useUpdateUserRoleMutation({
    userForm,
    onSuccess: ({user}: {user: UserModel}) => {
      refetch();
      handleClose();
      successToast.handleToast({message: `${user.fullName} assigned as Super Admin.`});
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const handleSubmitAssignSuperAdmin = () => {
    form.setFieldValue('userForm.role', UserRole.SUPER_ADMIN);
    setTimeout(handleSubmit, 0);
  };

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        const {superAdminOptions} = data;
        const superAdminOptionList = getSuperAdminOptions({superAdminOptions});
        return (
          <DrawerWithAction
            isOpen={isOpen}
            width={Drawer.WIDTH.DEFAULT}
            headerText={'Assign Super Admin'}
            handleClose={handleClose}
            isResponsive={false}
            isSubmitting={submitting}
            handleSubmit={handleSubmitAssignSuperAdmin}
            primaryActionText={'Save'}
            secondaryAction={handleClose}
          >
            <Callout
              text={
                'Super Admins can access all companies in Supermove and must be Supermove employees.'
              }
            />
            <FieldsContainer responsive={responsive}>
              <FieldInput
                {...form}
                component={DropdownInput}
                name={'userForm.userId'}
                label={'Users'}
                isRequired
                isResponsive
                input={{
                  options: superAdminOptionList,
                  placeholder: 'Select user',
                  isPortaled: true,
                  setFieldValue: form.setFieldValue,
                  value: _.get(form.values, 'userForm.userId'),
                  style: {
                    flex: 1,
                  },
                }}
              />
            </FieldsContainer>
          </DrawerWithAction>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignSuperAdminDrawer.query = gql`
  ${UserForm.edit.fragment}

  query AssignSuperAdminDrawer{
    ${gql.query}
    superAdminOptions {
      id
      fullName
      ...UserForm_edit
    }
  }
`;

export default AssignSuperAdminDrawer;
