// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import GlobalDashboardForm, {
  GlobalDashboardFormProps,
} from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';

const useUpdateGlobalDashboardMutation = ({
  globalDashboardForm,
  onSuccess,
  onError,
}: {
  globalDashboardForm: GlobalDashboardFormProps;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      globalDashboardForm: GlobalDashboardForm.toForm(globalDashboardForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateGlobalDashboardMutation.mutation,
    variables: {
      globalDashboardForm: GlobalDashboardForm.toMutation(form.values.globalDashboardForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateGlobalDashboardMutation.mutation = gql`
  mutation useUpdateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {
    response: updateGlobalDashboard(globalDashboardForm: $globalDashboardForm) {
      ${gql.errors}
      globalDashboard {
        id
        uuid
        name
      }
    }
  }
`;

export default useUpdateGlobalDashboardMutation;
