// Supermove
import {useNavigation, useMountEffect} from '@supermove/hooks';

const CompanyRootPage = () => {
  const {navigator, params} = useNavigation();

  useMountEffect(() => {
    // TODO(Hammad) Currently the back navigation behavior from the CompanyDetails pages back to the CompanyList does not work as intended.
    navigator.replace('CompanyDetailsProductsPage', {slug: params.slug});
  });

  return null;
};

export default CompanyRootPage;
