import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useMemo} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ProgressModal from '@shared/design/components/ProgressModal';
import useProgress from '@shared/modules/App/hooks/useProgress';
import AddSalesAppToCompanyStep from '@shared/modules/SupermoveProduct/enums/AddSalesAppToCompanyStep';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';
import useUpsertSupermoveProductMutation from '@shared/modules/SupermoveProduct/hooks/useUpsertSupermoveProductMutation';

const MobilePaddingContainer = Styled.View`
  padding-horizontal: ${({responsive}: any) => (responsive.desktop ? 0 : 16)}px;
`;

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const STEPS = AddSalesAppToCompanyStep.STEPS.map((step) => ({
  kind: step.kind,
}));

interface ProgressFooterProps {
  handleSubmit: any;
}

const ProgressFooter = ({handleSubmit}: ProgressFooterProps) => {
  return <Button text={'Submit'} onPress={handleSubmit} />;
};

interface StepFieldsProps {
  stepKind: string;
  form: any;
  field: string;
  setCurrentStepKind: any;
  responsive: any;
}

const StepFields = ({stepKind, form, field, setCurrentStepKind, responsive}: StepFieldsProps) => {
  switch (stepKind) {
    case AddSalesAppToCompanyStep.LICENSES:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <FieldInput
            {...form}
            name={`${field}.numberOfLicenses`}
            label={'Number of Licenses'}
            isResponsive
            isRequired
            input={{
              placeholder: 'Enter number of licenses',
            }}
          />
        </MobilePaddingContainer>
      );
    default:
      return null;
  }
};

interface AddSalesAppToCompanyModalBodyProps {
  form: any;
  field: string;
  currentStep: any;
  currentStepKind: string;
  setCurrentStepKind: any;
}

const AddSalesAppToCompanyModalBody = ({
  form,
  field,
  currentStep,
  currentStepKind,
  setCurrentStepKind,
}: AddSalesAppToCompanyModalBodyProps) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <MobilePaddingContainer responsive={responsive}>
        <StepHeaderText responsive={responsive}>{currentStep.title}</StepHeaderText>
        <Space height={8} />
        <StepHeaderDescription responsive={responsive}>
          {currentStep.description}
        </StepHeaderDescription>
      </MobilePaddingContainer>
      <Space height={16} />
      <StepFields
        stepKind={currentStepKind}
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        responsive={responsive}
      />
      {responsive.desktop && <Space height={24} />}
    </React.Fragment>
  );
};

interface AddSalesAppToCompanyModalProps {
  isOpen: boolean;
  handleClose: any;
  refetch: any;
  organization: any;
}

const AddSalesAppToCompanyModal = ({
  isOpen,
  handleClose,
  refetch,
  organization,
}: AddSalesAppToCompanyModalProps) => {
  const supermoveProductForm = useMemo(
    () =>
      SupermoveProductForm.new({
        kind: SupermoveProductKind.SALES,
        status: SupermoveProductStatus.ACTIVE,
        companyId: organization.company.id,
      }),
    [organization],
  );

  const {form, handleSubmit} = useUpsertSupermoveProductMutation({
    supermoveProductForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    nextStepKind,
    completedSteps,
    setCompletedSteps,
    allStepKinds,
    nextStepToComplete,
    currentStepIndex,
  } = useProgress({
    steps: STEPS,
    getPreviousStepKind: AddSalesAppToCompanyStep.getPreviousStepKind,
    getNextStepKind: AddSalesAppToCompanyStep.getNextStepKind,
  });

  const currentStep = useMemo(
    () => AddSalesAppToCompanyStep.getStep(currentStepKind),
    [currentStepKind],
  );

  return (
    <ProgressModal
      isOpen={isOpen}
      handleClose={handleClose}
      steps={STEPS}
      completedSteps={completedSteps}
      isViewingFirstStep={isViewingFirstStep}
      currentStepKind={currentStepKind}
      getStepTitle={(kind) => AddSalesAppToCompanyStep.getStep(kind).title}
      setCurrentStepKind={setCurrentStepKind}
      nextStepToComplete={nextStepToComplete}
      currentStepIndex={currentStepIndex}
      title={'Set Up AI Sales Copilot'}
      handleBack={
        isViewingFirstStep
          ? null
          : () => {
              previousStepKind && setCurrentStepKind(previousStepKind);
            }
      }
      BodyElement={
        <AddSalesAppToCompanyModalBody
          form={form}
          field={'supermoveProductForm'}
          currentStep={currentStep}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
        />
      }
      FooterElement={<ProgressFooter handleSubmit={handleSubmit} />}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddSalesAppToCompanyModal.fragment = gql`
  fragment AddSalesAppToCompanyModal on Organization {
    id
    company {
      id
    }
  }
`;

export default AddSalesAppToCompanyModal;
