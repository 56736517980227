// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import DashboardCategoryForm, {
  DashboardCategoryFormType,
} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';

const useDeleteDashboardCategoryMutation = ({
  dashboardCategoryForm,
  onSuccess,
  onError,
}: {
  dashboardCategoryForm: DashboardCategoryFormType;
  onSuccess: () => void;
  onError: (errors: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardCategoryForm: DashboardCategoryForm.toForm(dashboardCategoryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteDashboardCategoryMutation.mutation,
    variables: {
      dashboardCategoryForm: DashboardCategoryForm.toMutation(form.values.dashboardCategoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteDashboardCategoryMutation.mutation = gql`
  mutation useDeleteDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {
    response: deleteDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {
      ${gql.errors}
      dashboardCategory {
        id
      }
    }
  }
`;

export default useDeleteDashboardCategoryMutation;
