// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ItemText = Styled.Text`
  ${({vars}) => (vars.isSelected || vars.isHovered ? Typography.Label : Typography.Body)}
  color: ${({color}) => color};
`;

const SidebarItemContainer = Styled.View`
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.gray.border : colors.transparent)};
  border-radius: 4px;
  background-color: ${({isHovered, isSelected}) =>
    isHovered || isSelected ? colors.gray.background : colors.transparent};
  flex-direction: row;
`;

const SidebarButton = Styled.ButtonV2``;

const getColor = ({isHovered, isSelected}) =>
  isSelected ? colors.blue.interactive : isHovered ? colors.gray.primary : colors.gray.tertiary;

const TooltipWrapper = ({name, isExpanded, children}) => {
  if (isExpanded) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <TextTooltip text={name} placement={'right'}>
      {children}
    </TextTooltip>
  );
};

const SidebarItemButton = ({
  innerRef,
  isSelected,
  isHovered,
  isExpanded,
  name,
  onPress,
  children,
}) => {
  return (
    <TooltipWrapper name={name} isExpanded={isExpanded}>
      <SidebarButton onPress={onPress}>
        <SidebarItemContainer ref={innerRef} isSelected={isSelected} isHovered={isHovered}>
          {children}
        </SidebarItemContainer>
      </SidebarButton>
    </TooltipWrapper>
  );
};

const SidebarItem = ({name, isSelected, icon, onPress, isExpanded}) => {
  const {isHovered, ref} = useHover();
  return (
    <SidebarItemButton
      innerRef={ref}
      onPress={onPress}
      isSelected={isSelected}
      isHovered={isHovered}
      isExpanded={isExpanded}
      name={name}
    >
      <Icon
        source={icon}
        color={getColor({isHovered, isSelected})}
        size={20}
        style={{height: 20, width: 20}}
      />
      {isExpanded && (
        <Row style={{flex: 1}}>
          <Space width={8} />
          <ItemText vars={{isSelected, isHovered}} color={getColor({isHovered, isSelected})}>
            {name}
          </ItemText>
        </Row>
      )}
    </SidebarItemButton>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SidebarItem.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  onPress: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
};

SidebarItem.defaultProps = {
  isExpanded: true,
};

export default SidebarItem;
