// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToast} from '@supermove/hooks';
import {DashboardCategoryModel, DashboardTagModel, GlobalDashboardModel} from '@supermove/models';

// App
import Callout from '@shared/design/components/Callout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';
import GlobalDashboardForm from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';
import useUpdateGlobalDashboardMutation from '@shared/modules/GlobalDashboard/hooks/useUpdateGlobalDashboardMutation';
import EditGlobalDashboardForm from 'modules/Dashboards/components/EditGlobalDashboardForm';

const UpdateGlobalDashboardDrawerContent = ({
  isOpen,
  isLoading,
  globalDashboard,
  dashboardCategories,
  dashboardTags,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  isLoading: boolean;
  globalDashboard: GlobalDashboardModel;
  dashboardCategories: DashboardCategoryModel[];
  dashboardTags: DashboardTagModel[];
  handleClose: () => void;
  refetch: () => void;
}) => {
  const updateGlobalDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard updated!',
  });

  const globalDashboardForm = GlobalDashboardForm.edit(globalDashboard);
  const {form, submitting, handleSubmit} = useUpdateGlobalDashboardMutation({
    globalDashboardForm,
    onSuccess: ({globalDashboard}) => {
      updateGlobalDashboardSuccessToast.handleToast({
        message: `'${globalDashboard.name}' updated!`,
      });
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Edit Global Report'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={isLoading}
    >
      <Loading loading={isLoading}>
        {() => (
          <React.Fragment>
            <Callout text='You are editing a Global Report. Changes made will affect any company who has this report.' />
            <Space height={16} />
            <EditGlobalDashboardForm
              form={form}
              dashboardCategories={dashboardCategories}
              dashboardTags={dashboardTags}
            />
          </React.Fragment>
        )}
      </Loading>
    </DrawerWithAction>
  );
};

const UpdateGlobalDashboardDrawer = ({
  isOpen,
  globalDashboardUuid,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  globalDashboardUuid: string;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery(UpdateGlobalDashboardDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      globalDashboardUuid,
    },
  });

  if (!data) {
    return null;
  }

  return (
    <UpdateGlobalDashboardDrawerContent
      isOpen={isOpen}
      isLoading={!data || loading}
      globalDashboard={data.globalDashboard}
      dashboardCategories={data.dashboardCategories}
      dashboardTags={data.dashboardTags}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateGlobalDashboardDrawer.query = gql`
  ${DashboardCategoryForm.edit.fragment}
  ${DashboardTagForm.edit.fragment}
  ${GlobalDashboardForm.edit.fragment}
  query UpdateGlobalDashboardDrawer($globalDashboardUuid: String!) {
    ${gql.query}
    globalDashboard(uuid: $globalDashboardUuid) {
      id
      ...GlobalDashboardForm_edit
    }
    dashboardCategories {
      id
      ...DashboardCategoryForm_edit
    }
    dashboardTags {
      id
      ...DashboardTagForm_edit
    }
  }
`;

export default UpdateGlobalDashboardDrawer;
