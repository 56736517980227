// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DashboardCategoryForm, {
  DashboardCategoryFormType,
} from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';

const useUpdateDashboardCategoryMutation = ({
  dashboardCategoryForm,
  onSuccess,
  onError,
}: {
  dashboardCategoryForm: DashboardCategoryFormType;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      dashboardCategoryForm: DashboardCategoryForm.toForm(dashboardCategoryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDashboardCategoryMutation.mutation,
    variables: {
      dashboardCategoryForm: DashboardCategoryForm.toMutation(form.values.dashboardCategoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDashboardCategoryMutation.mutation = gql`
  mutation useUpdateDashboardCategoryMutation($dashboardCategoryForm: DashboardCategoryForm!) {
    response: updateDashboardCategory(dashboardCategoryForm: $dashboardCategoryForm) {
      ${gql.errors}
      dashboardCategory {
        id
      }
    }
  }
`;

export default useUpdateDashboardCategoryMutation;
